import React, { useState } from "react";
import ProjectCard from "../components/ProjectCard";
import scotts_youtube from "../assets/scotts_youtube.png";
import restaurant_landing_page from "../assets/restaurant_landing_page.png";
import "./Projects.css";

const projectData = [
  {
    title: "YouTube API",
    image: scotts_youtube,
    description: "",
    technologies: ["React", "CSS", "API"],
    link: "https://scottianrobertsonyoutubeapi.netlify.app/",
    codeLink: "https://github.com/ScottIanRobertson/youtube_api",
  },
  {
    title: "Restaurant Landing Page",
    image: restaurant_landing_page,
    description: "",
    technologies: ["React", "CSS"],
    link: "https://restaurantlandingpagetemplate.netlify.app/",
    codeLink: "https://github.com/ScottIanRobertson/restaurant-landing-page",
  },
];

function Projects() {
  const [searchTerm, setSearchTerm] = useState("");

  // Filter projects based on search term
  const filteredProjects = projectData.filter((project) => {
    return (
      project.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.technologies.some((tech) =>
        tech.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  });

  return (
    <div className="projects">
      <h2>Projects</h2>{" "}
      <div className="border-box">
        <div className="input-container">
          <input
            type="text"
            placeholder="Search projects..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-bar"
          />
        </div>
      </div>
      <div className="project-list">
        {filteredProjects.map((project, index) => (
          <ProjectCard key={index} {...project} />
        ))}
      </div>
    </div>
  );
}

export default Projects;
