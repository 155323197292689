import React from "react";
import "./ProjectCard.css";

function ProjectCard({
  title,
  image,
  description,
  technologies,
  link,
  codeLink,
}) {
  return (
    <div className="project-card">
      <div class="border-box">
        <h3>{title}</h3>
        <img className="project-image" src={image} alt={title} />
        <p>{description}</p>
        <p>
          <strong>Technologies:</strong> {technologies.join(", ")}
        </p>
        <a href={link} target="_blank" rel="noopener noreferrer">
          View Project
        </a>
        <br></br>
        <a href={codeLink} target="_blank" rel="noopener noreferrer">
          View Code
        </a>
      </div>
    </div>
  );
}

export default ProjectCard;
