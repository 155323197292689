import React from "react";

function About() {
  return (
    <div className="about">
      <h2>About Me</h2>
      <div class="border-box">
        <p>
          I'm a junior React developer with a passion for building user-friendly
          applications...
        </p>
        <p>
          <strong>Skills:</strong> React, JavaScript, HTML, CSS, Git, etc.
        </p>
      </div>
    </div>
  );
}

export default About;
