import React from "react";

function Contact() {
  return (
    <div className="contact">
      <h2>Contact Me</h2>
      <div class="border-box">
        <p>Email: scottianrobertson@gmail.com</p>
        <br></br>
        <p>Feel free to reach out for collaboration or job opportunities!</p>
      </div>
    </div>
  );
}

export default Contact;
