import React from "react";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="home">
      <h2>Welcome to My Portfolio</h2>
      <div class="border-box">
        <p>Hi, I’m Scott, a passionate React developer.</p>
        <br></br>
        <Link to="/projects" reloadDocument={true}>
          View My Work
        </Link>
      </div>
    </div>
  );
}

export default Home;
