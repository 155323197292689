import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header>
      <div className="menu-header">
        <Link to="/">
          <div className="border-box">
            <h1>SIR</h1>
          </div>
        </Link>
        <button className="menu-toggle" onClick={toggleMenu}>
          <div className="border-box">☰</div>
        </button>
      </div>

      <nav className={menuOpen ? "open" : ""}>
        <Link to="/" onClick={toggleMenu}>
          <div className="border-box">Home</div>
        </Link>
        <Link to="/about" onClick={toggleMenu}>
          <div className="border-box">About</div>
        </Link>
        <Link to="/projects" onClick={toggleMenu}>
          <div className="border-box">Projects</div>
        </Link>
        <Link to="/contact" onClick={toggleMenu}>
          <div className="border-box">Contact</div>
        </Link>
      </nav>
      {/* Menu Toggle Button for Mobile */}
    </header>
  );
}

export default Header;
