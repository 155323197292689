import React from "react";
import { FaGithub } from "react-icons/fa";
import "./Footer.css";

function Footer() {
  return (
    <footer>
      <div className="social-links">
        <a
          href="https://github.com/ScottIanRobertson"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="GitHub"
        >
          <FaGithub />
        </a>
      </div>
      <p>© 2024 Scott Robertson. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
